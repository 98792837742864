import React from 'react'
import Title from './Title'
import Line from './Line'
import List, {Item} from './List'
import {StyleSheet, Text, View} from '@react-pdf/renderer'
import moment from 'moment'

const styles = StyleSheet.create({

    entryContainer: {
        marginBottom: 10,
    },
    date: {
        fontSize: 11,
    },
    detailContainer: {
        flexDirection: 'row',
    },
    bulletPoint: {
        fontSize: 10,
    },
    details: {
        fontSize: 10,
    },
    headerContainer: {
        flexDirection: 'row',
        marginBottom: 10,
    },
    leftColumn: {
        flexDirection: 'column',
        flexGrow: 9,
    },
    title: {
        fontSize: 11,
        color: 'black',
        textDecoration: 'none',
    },
    line: {
        backgroundColor: '#f8014d',
    }
})

const EducationsEntry = ({title, subtitle, startDate, finishDate, description,}) => {

    function isDesc(desc) {
        if (desc) {
            return (
                <List>
                    <Item style={styles.detailContainer}>
                        {description}
                    </Item>
                </List>
            )
        }
    }

    function getRangeDate(startDate, finishDate) {
        let s = moment(startDate).format('MM/YYYY')
        let f = finishDate === "" ? moment(new Date()).format('MM/YYYY') : moment(finishDate).format('MM/YYYY')
        return s + ' - ' + f
    }

    return (
        <View style={styles.entryContainer}>
            <View style={styles.headerContainer}>
                <View style={styles.leftColumn}>
                    <Text style={styles.title}>{title}</Text>
                    <Text style={styles.title}>{subtitle}</Text>
                    <Text style={styles.date}>{getRangeDate(startDate, finishDate)}</Text>
                </View>
            </View>
            {isDesc()}
        </View>
    )
}

const Educations = (props) => {

    const {educations, lang, translate} = props

    return (
        <View>
            <Title>
                {translate.translate.ABOUT.EDUCATION_AND_CERTIFICATION}
            </Title>
            <Line/>
            {educations.map((el, key) => (
                <EducationsEntry
                    key={key}
                    title={el.title}
                    subtitle={el.subtitle}
                    startDate={el.startDate}
                    finishDate={el.finishDate}
                    description={el.description[lang]}
                />
            ))}
        </View>
    )
}

export default Educations
