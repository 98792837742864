import React from "react";
import { Route, Switch } from "react-router-dom";

const TheLayout = React.lazy(() => import('../containers/admin/TheLayout'));

export const DashboardRoutes = () => {
    return (
        <Switch>
            <Route path="/admin" name="Home" render={props => <TheLayout {...props} />} />
        </Switch>
    );
}