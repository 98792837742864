import React, { useContext } from "react"
import { LanguageContext } from "../../utils/context/LanguageContext"
import Slider from "react-slick"
import { projectsSlick } from '../../utils/slick'


const ProjectsElement = (props) => {

    const { currentLang } = useContext(LanguageContext)
    const projects = props.data.projects
    const TRANSLATE = currentLang.translate

    return (
        <div id="projects" className="fix">
            <div className="projects-area ptb--120 bg_color--5">
                <div className="projects-sacousel-inner mb--55">
                    <div className="projects-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="section-title text-center">
                                        <h2 className="title">{TRANSLATE.PROJECT.MY_PROJECT}</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="projects-slick-activation mt--70 mt_sm--40">
                            <Slider {...projectsSlick}>
                                {projects.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate)).map((value, index) => (
                                    <div className="projects" key={index}>
                                        <div className="thumbnail-inner">
                                            <div className="thumbnail" style={{ backgroundColor: `${value.image}` }}></div>
                                            <div className="bg-blr-image" style={{ backgroundColor: `${value.image}` }}></div>
                                        </div>
                                        <div className="content">
                                            <div className="inner">
                                                <h4><a href={value.link}>{value.title}</a></h4>
                                                <p>{value.category + ' - ' + value.subtitle}</p>
                                                <div className="projects-button">
                                                    <a className="rn-button-style--2 btn-solid" href={value.link}>DEMO</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProjectsElement
