import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import SpinnerComponnet from "../components/shared/Spinner";
import { AuthContext } from "../utils/context/AuthProvider";

const PrivateRoute = ({ component: RouteComponent, ...rest }) => {
    const { authenticated, loadingAuthState } = useContext(AuthContext);

    if (loadingAuthState) {
        return <SpinnerComponnet></SpinnerComponnet>
    }

    return (
        <Route
            {...rest}
            render={routeProps =>
                authenticated ? (
                    <RouteComponent {...routeProps} />
                ) : (
                        <Redirect to={{ pathname: "/auth/login", state: { prevPath: rest.path } }} />
                    )
            }
        />
    );
}

export default PrivateRoute