import React from 'react'
import { LanguageContext } from '../../utils/context/LanguageContext'
import { useHistory } from "react-router-dom"

const LanguageSwitcher = () => {

    const history = useHistory()
    const lang = window.location.pathname.includes("/it") ? "it" : "en"

    return (
        <LanguageContext.Consumer>
            {({ currentLang, updateLang }) => {
                return (
                    <div style={{ width: 48 + 'px', height: 48 + 'px', borderRadius: 100 + '%', marginTop: 0 + 'px' }} onClick={() => {
                        history.push(lang === 'it' ? '/' : '/it')
                        updateLang(lang === 'it' ? 'en' : 'it')
                    }}>
                        {lang === 'it' ?
                            <img style={{ width: 24 + 'px', height: 24 + 'px', backgroundSize: 100 + '%', margin: 0, cursor: 'pointer' }} src="/assets/images/icons/english.png" alt="english" /> :
                            <img style={{ width: 24 + 'px', height: 24 + 'px', backgroundSize: 100 + '%', margin: 0, cursor: 'pointer' }} src="/assets/images/icons/italiano.png" alt="italiano" />}
                    </div>
                )
            }}
        </LanguageContext.Consumer>
    )
}

export default LanguageSwitcher