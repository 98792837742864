import React, { useEffect, useState } from "react"
import FirestoreService from "../../firebase"

export const AuthContext = React.createContext({
    user: null,
    isAdmin: false,
    authenticated: false,
    setUser: () => { },
    loadingAuthState: false,
})

export const AuthProvider = ({ children }) => {

    const [user, setUser] = useState(null)
    const [loadingAuthState, setLoadingAuthState] = useState(true)

    useEffect(() => {
        FirestoreService.auth().onAuthStateChanged((user) => {
            if (user) {
                FirestoreService.firestore().collection('users').where('uid', '==', user.uid).get().then(res => {
                    setUser(res.docs[0].data())
                    setLoadingAuthState(false)
                })
            } else {
                setUser(null)
                setLoadingAuthState(false)
            }
        })
    }, [])

    return (
        <AuthContext.Provider
            value={{
                user,
                isAdmin: user ? user.role === 'admin' : false,
                authenticated: user !== null,
                setUser,
                loadingAuthState
            }}>
            {children}
        </AuthContext.Provider>
    )
}