import React, { useContext, useEffect } from "react"
import { Link } from "react-router-dom"
import { FiUser } from "react-icons/all"
import { ThemeContext } from "../../utils/context/ThemeProvider"
import ThemeToggle from "../../components/toggle/ThemeToggle"
import LanguageToggle from "../../components/toggle/LanguageToggle"

const HeaderElement = () => {

    const stateTheme = useContext(ThemeContext)

    const SocialShare = [
        { Social: <ThemeToggle />, link: '', type: 'button' },
        { Social: <LanguageToggle />, link: '', type: 'button' },
        { Social: <FiUser color={stateTheme.theme.type === 'dark' ? 'white' : 'black'} />, link: '', type: 'link' }
    ]

    useEffect(
        () => {
            window.addEventListener('scroll', function () {
                var value = window.scrollY
                if (value > 100) {
                    if (document.querySelector('.header--fixed') !== null) {
                        document.querySelector('.header--fixed').classList.add('sticky')
                    }
                } else {
                    if (document.querySelector('.header--fixed') !== null) {
                        document.querySelector('.header--fixed').classList.remove('sticky')
                    }
                }
            })

            var elements = document.querySelectorAll('.has-droupdown > a')

            for (var i in elements) {
                if (elements.hasOwnProperty(i)) {
                    elements[i].onclick = function () {
                        this.parentElement.querySelector('.submenu').classList.toggle("active")
                        this.classList.toggle("open")
                    }
                }
            }
        }
    )

    return (
        <header className={`header-area header-style-two header--fixed ${stateTheme.theme.color}`}>
            <div className="header-wrapper">
                <div className="header-left d-flex align-items-center">
                    <div className="logo">
                        <a href="/">
                            <h1 style={{ color: '#f9004d' }}>VS</h1>
                        </a>
                    </div>
                </div>
                <div className="header-right">
                    <div className="inner text-center">
                        <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                            {SocialShare.filter(val => val.type === 'link').map((val, i) => (
                                <li key={i}><Link to="/auth">{val.Social}</Link></li>
                            ))}
                            {SocialShare.filter(val => val.type === 'button').map((val, i) => (
                                <li key={i + 4}>{val.Social}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </header>
    )
}
export default HeaderElement