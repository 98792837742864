import React from 'react'
import { FaLinkedinIn, FaMailBulk, FaPhone } from "react-icons/fa"

const FooterElement = (props) => {

    const data = props.data

    const SocialShare = [
        { Social: <FaLinkedinIn />, link: data.info.linkedin },
        { Social: <FaMailBulk />, link: `mailto:${data.info.email}` },
        { Social: <FaPhone />, link: `tel:${data.info.phone}` }
    ]

    return (
        <div className="footer-left">
            <div className="wrapper plr--50 plr_sm--20">
                <div className="row align-items-center justify-content-between">
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner text-center mb-3">
                            <img style={{ width: 150 + 'px', height: 150 + 'px' }} src={`/assets/images/logo/logo-light.png`} alt="Vincenzo Spinelli" />
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                        <div className="inner text-center">
                            <ul className="social-share rn-lg-size d-flex justify-content-center liststyle">
                                {SocialShare.map((val, i) => (
                                    <li key={i}><a href={`${val.link}`}>{val.Social}</a></li>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-12 col-sm-12 col-12">
                        <div className="inner text-lg-right text-center mt_md--20 mt_sm--20">
                            <div className="text">
                                <p style={{ fontWeight: 'bold', fontSize: 20 + 'px', color: 'white' }}>Copyright © {new Date().getFullYear()} {data.info.firstName + ' ' + data.info.lastName}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FooterElement