import { CImg } from "@coreui/react"
import React from "react"
import TabsList from "./components/tabs/TabsList"

const AboutElements = (props) => {

    const data = props.data
    const lang = props.lang

    return (
        <div id="about" className="fix">
            <div className="about-area ptb--120 bg_color--1">
                <div className="about-wrapper">
                    <div className="container">
                        <div className="row row--35 align-items-center text-center">
                            <div className="col-lg-5">
                                <div className="thumbnail-rounded">
                                    <CImg
                                        src={data.info.image}
                                        alt="About Images" />
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="about-inner inner">
                                    <div className="section-title">
                                        <h2 className="title">About Me</h2>
                                        <p className="description">{data.info.about[lang]}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row row--35 align-items-center">
                            <div className="col-lg-12">
                                <div className="about-inner inner">
                                    <div className="row mt--5">
                                        <TabsList
                                            tabStyle="tab-style--1"
                                            lang={lang}
                                            educations={data.educations}
                                            experiences={data.experiences}
                                            projects={data.projects}
                                            skills={data.skills} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutElements
