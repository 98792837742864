import React, { useState, useEffect } from "react"
import Particles from 'react-particles-js'
import * as FirestoreService from "../../firebase"

const ParticlesComponent = () => {

    const [dimension, setDimension] = useState(window.innerWidth)
    const [particles, setParticles] = useState([])

    const bgStyle = {
        position: "absolute",
        width: "100%",
        top: 0,
        left: 0,
        backgroundColor: 'tranparent'
    }

    useEffect(() => {
        setDimension(window.innerWidth)
        getParticles()
    }, [dimension])

    async function getParticles() {
        FirestoreService.getParticles().then(res => {
            const items = res.docs.map(docSnapshot => docSnapshot.data())
            setParticles(items.map(el => {
                return {
                    src: el.image,
                    width: el.width,
                    height: el.height
                }
            }))
        })
    }

    return (
        <Particles
            params={{
                particles: {
                    number: {
                        value: dimension <= 769 ? 30 : 50,
                    },
                    shape: {
                        type: 'images',
                        image: particles
                    },
                    line_linked: {
                        enable: false,
                    },
                    size: {
                        value: dimension <= 769 ? 25 : 50,
                        random: true
                    }
                }
            }} style={bgStyle} />
    )
}

export default ParticlesComponent
