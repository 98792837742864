import { CImg } from '@coreui/react'
import React, { useContext } from 'react'
import { ThemeContext } from '../../../../utils/context/ThemeProvider'

const SkillItem = (props) => {

    const state = useContext(ThemeContext)
    let { img, color, name } = props

    var circleStyle = {
        margin: 10 + 'px',
        position: 'relative',
        display: "inline-block",
        backgroundColor: state.theme.type === 'dark' ? 'black' : 'white',
        border: '5px solid ' + color,
        borderRadius: 50 + '%',
        width: 120 + 'px',
        height: 120 + 'px'
    };

    var imageStyle = {
        width: 70,
        height: 70,
        position: 'absolute',
        margin: 'auto',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0
    }

    return (
        <div style={circleStyle} >
            <CImg style={imageStyle} src={img} alt={name} />
        </div>
    )
}

export default SkillItem