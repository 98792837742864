import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

const Login = React.lazy(() => import('../views/admin/Login'));

export const AuthRoutes = () => {
    return (
        <Switch>
            <Route exact path="/auth/login" name="Login" render={props => <Login {...props} />} />
            <Redirect to="/auth/login" from="/auth" />
        </Switch>
    );
}