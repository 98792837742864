import React, { useContext } from 'react'
import Item from './SkillItem'
import { ThemeContext } from '../../../../utils/context/ThemeProvider'

const SkillsGroup = (props) => {

    const state = useContext(ThemeContext)
    let { name, items } = props

    function themeStyle() {
        return state.theme.type === 'dark' ? 'white' : 'black'
    }

    return (
        <div className="column">
            <h4 style={{ margin: 10 + 'px ' + 20 + 'px', color: themeStyle(), fontWeight: 'bold' }}>
                {name}
            </h4>
            <div className="row">
                {
                    items.map((item, key) =>
                        <Item key={key}
                            img={item.image}
                            name={item.name}
                            color={item.color}
                            value={item.value} />
                    )
                }
            </div>
        </div>
    )
}

export default SkillsGroup