import React from "react"
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom"
import { AuthRoutes } from "../routes/AuthRoutes"
import { DashboardRoutes } from "../routes/DashboardRoutes"
import PrivateRoute from "../routes/PrivateRoutes";
import TheLayoutHome from "../containers/home/TheLayout"
import ErrorSection from '../views/home/ErrorSection'

const ApplicationRoutes = () => {
    return (
        <Router>
            <Switch>
                <Route exact path="/" component={TheLayoutHome} />
                <Route exact path="/it" component={TheLayoutHome} />
                <PrivateRoute path="/admin" component={DashboardRoutes} />
                <Route path="/auth" component={AuthRoutes} />
                <Redirect to="/admin/dashboard" from="/admin" />
                <Route component={ErrorSection} />
            </Switch>
        </Router>
    );
}

export default ApplicationRoutes;


