import React, { useState, useContext } from "react"
import { Form, Button } from "react-bootstrap"
import { ThemeContext } from "../../utils/context/ThemeProvider"
import { LanguageContext } from "../../utils/context/LanguageContext"
import { FiHeadphones, FiMail } from "react-icons/fi"
import FirestoreService from '../../firebase'
import axios from 'axios'


const ContactElement = () => {

    const stateTheme = useContext(ThemeContext)
    const { currentLang } = useContext(LanguageContext)

    const TRANSLATE = currentLang.translate

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [message, setMessage] = useState("")

    const [resultMessage, setResultMessage] = useState("")
    const [sending, setSending] = useState(false)

    const handleSubmit = e => {
        e.preventDefault()
        setSending(true)

        axios({
            method: 'post',
            url: `https://api.mailgun.net/v3/sandbox04c536c1032b4d7589396b4c6c5315e7.mailgun.org/messages`,
            auth: {
                username: 'api',
                password: '475e29a260f8a3b2c26b83a7849c61f0-9b1bf5d3-29a4fcf7'
            },
            params: {
                from: `${name} <contact@vincenzospinelli.it>`,
                to: 'contact@vincenzospinelli.it',
                subject: `From vincenzospinelli.it - ${email} - ${name}`,
                text: message
            }
        }).then(() => {
            setResultMessage('Success')
            FirestoreService.firestore().collection("emails").add({
                name: name,
                email: email,
                message: message,
                created: new Date().toISOString()
            }).then(docRef => {
                console.log('Success')
            })
        })
            .catch(error => {
                setResultMessage(error.message)
                console.log(error)
            })
            .finally(() => {
                setName("")
                setEmail("")
                setMessage("")
                setSending(false)

                setTimeout(() => {
                    setResultMessage("")
                }, 5000)
            })
    }

    function boxStyle() {
        if (stateTheme.theme.type === 'dark') {
            return { background: '#191919', borderColor: '#191919' }
        } else {
            return { background: 'white', borderColor: 'white' }
        }
    }

    function fontStyle() {
        if (stateTheme.theme.type === 'dark') {
            return { color: 'white' }
        } else {
            return { color: 'black' }
        }
    }

    return (
        <div id="contact" className="fix">
            <div className="rn-contact-area ptb--120 bg_color--5">
                <div className="contact-form--1">
                    <div className="container">
                        <div className="row row--35 align-items-start">
                            <div className="col-lg-6 order-2 order-lg-1">
                                <div className="section-title text-center mb--50">
                                    <h2 className="title">{TRANSLATE.CONTACT.CONTACT_ME}</h2>
                                </div>
                                <div className={'form-wrapper ' + (stateTheme.theme.type === 'dark' ? 'text-white' : 'text-dark')}>
                                    <Form>
                                        <Form.Group>
                                            <Form.Label>{TRANSLATE.CONTACT.NAME}</Form.Label>
                                            <Form.Control
                                                style={{ color: 'black' }}
                                                type="text"
                                                value={name}
                                                name="name"
                                                onChange={(e) => setName(e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{TRANSLATE.CONTACT.EMAIL}</Form.Label>
                                            <Form.Control
                                                style={{ color: 'black' }}
                                                type="email"
                                                placeholder=""
                                                value={email}
                                                name="email"
                                                onChange={(e) => setEmail(e.target.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group>
                                            <Form.Label>{TRANSLATE.CONTACT.MESSAGE}</Form.Label>
                                            <Form.Control
                                                style={{ color: 'black' }}
                                                as="textarea"
                                                rows="5"
                                                value={message}
                                                name="message"
                                                onChange={(e) => setMessage(e.target.value)}
                                            />
                                        </Form.Group>
                                    </Form>
                                    <Button
                                        className="rn-button-style--2 btn-solid"
                                        type="submit"
                                        disabled={!name || !email || !message}
                                        onClick={handleSubmit}>
                                        {TRANSLATE.CONTACT.SEND}
                                    </Button>

                                    <div className="text-center mt--50" style={{ color: "#00000" }}>
                                        <h3>{resultMessage}</h3>
                                        {
                                            (sending)
                                                ? <div className="spinner-grow text-primary" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                                : <div></div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 order-1 order-lg-2">
                                <div className="row mb-5">
                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt-5 mb-5">
                                        <div className="rn-address" style={boxStyle()}>
                                            <div className="icon">
                                                <FiHeadphones />
                                            </div>
                                            <div className="inner">
                                                <h4 className="title">{TRANSLATE.CONTACT.PHONE_NUMBER}</h4>
                                                <p style={fontStyle()}><a style={fontStyle()} href="tel:+39 3341730866">+39 3341730866</a></p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-12 col-md-12 col-sm-12 col-12 mt_mobile--50 mt-5 mb-5">
                                        <div className="rn-address" style={boxStyle()}>
                                            <div className="icon">
                                                <FiMail />
                                            </div>
                                            <div className="inner">
                                                <h4 className="title">{TRANSLATE.CONTACT.EMAIL_ADDRESS}</h4>
                                                <p style={fontStyle()}><a style={fontStyle()} href="mailto:contact@vincenzospinelli.it">contact@vincenzospinelli.it</a></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactElement