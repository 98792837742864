import React from 'react'
import Title from './Title'
import {StyleSheet, Text, View} from '@react-pdf/renderer'
import Line from "./Line";
import moment from "moment";

const styles = StyleSheet.create({
    container: {
        flexWrap: 'no-wrap',
        paddingTop: 15,
        paddingLeft: 15,
        '@media max-width: 400': {
            paddingTop: 10,
            paddingLeft: 0,
        },
    },
    entryContainer: {
        marginBottom: 10,
    },
    date: {
        fontSize: 9
    },
    description: {
        fontSize: 9,
        paddingLeft: 10
    },
    headerContainer: {
        flexDirection: 'row',
        marginBottom: 10
    },
    leftColumn: {
        flexDirection: 'column',
        flexGrow: 9
    },
    rightColumn: {
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'flex-end',
        justifySelf: 'flex-end'
    },
    title: {
        fontSize: 10,
        textTransform: 'uppercase',
        textDecoration: 'underline',
        color: '#000'
    }
})

const ProjectEntry = ({title, subtitle, desc, startDate, finishDate, technologiesUsed, link}) => {
    const t = `${title} | ${subtitle}`

    function getRangeDate(startDate, finishDate) {
        let s = moment(startDate).format('MM/YYYY')
        let f = finishDate === "" ? moment(new Date()).format('MM/YYYY') : moment(finishDate).format('MM/YYYY')
        return s + ' - ' + f
    }

    return (
        <View style={styles.entryContainer}>
            <View style={styles.headerContainer}>
                <View style={styles.leftColumn}>
                    <Text style={styles.title}>{t}</Text>
                </View>
                <View style={styles.rightColumn}>
                    <Text style={styles.date}>{getRangeDate(startDate, finishDate)}</Text>
                </View>
            </View>
            <Text style={styles.description}>
                {desc}
            </Text>
            <Text style={{fontSize: 10, paddingLeft: 10, paddingTop: 10}}>
                Tecnologie utilizzate: {technologiesUsed.map((t, i) => t + ' ')}
            </Text>
            <Text style={{fontSize: 10, paddingLeft: 10, paddingTop: 5}}>
                Link: {link}
            </Text>
        </View>
    )
}

const Project = (props) => {

    const {projects, lang, translate} = props

    return (
        <View style={styles.container}>
            <Title>
                {translate.translate.ABOUT.PROJECTS.TITLE}
            </Title>
            <Line/>
            {projects.map((el, i) => (
                <ProjectEntry
                    key={i}
                    title={el.title}
                    subtitle={el.subtitle}
                    startDate={el.startDate}
                    finishDate={el.finishDate}
                    desc={el.description[lang]}
                    technologiesUsed={el.technologiesUsed}
                    link={el.link}
                />
            ))}
        </View>
    )
}

export default Project
