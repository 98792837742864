import React from 'react'
import {Link, Text, View, StyleSheet} from '@react-pdf/renderer'

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'stretch',
        borderBottom: '5px solid #f8014d',
    },
    detailColumn: {
        marginBottom: '5px',
        flexDirection: 'column',
        flexGrow: 1,
        textTransform: 'uppercase',
    },
    linkColumn: {
        marginBottom: '5px',
        flexDirection: 'column',
        flexGrow: 2,
        alignSelf: 'flex-end',
        justifySelf: 'flex-end',
    },
    name: {
        fontSize: 24
    },
    subtitle: {
        fontSize: 14,
        justifySelf: 'flex-end',
        color: '#f8014d'
    },
    link: {
        fontSize: 10,
        color: 'black',
        textDecoration: 'none',
        alignSelf: 'flex-end',
        justifySelf: 'flex-end',
    },
})

export default (props) => {

    const {info} = props

    return (
        <View style={styles.container}>
            <View style={styles.detailColumn}>
                <Text style={styles.name}>{info.firstName + ' ' + info.lastName}</Text>
                <Text style={styles.subtitle}>{info.job}</Text>
            </View>
            <View style={styles.linkColumn}>
                <Link style={styles.link}>{info.email}</Link>
                <Link style={styles.link}>{info.phone}</Link>
            </View>
        </View>

    )
} 
