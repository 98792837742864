import React, { useContext } from "react"
import { Badge } from "react-bootstrap"
import { Timeline, TimelineEvent } from 'react-event-timeline'
import { ThemeContext } from "../../../../utils/context/ThemeProvider"
import { LanguageContext } from "../../../../utils/context/LanguageContext"
import moment from 'moment'


const Timelines = (props) => {

    const stateTheme = useContext(ThemeContext)
    const { currentLang } = useContext(LanguageContext)
    const lang = props.lang
    const TRANSLATE = currentLang.translate
    const { experiences, educations, projects } = props

    function createdAt() {
        if (stateTheme.theme.type === 'dark') {
            return { color: 'white' }
        } else {
            return { color: 'black' }
        }
    }

    function contentStyle() {
        if (stateTheme.theme.type === 'dark') {
            return { background: '#101010', color: 'white', borderRadius: 5 + 'px', fontSize: 18 + 'px' }
        } else {
            return { background: '#f8f9fc', borderRadius: 5 + 'px', fontSize: 18 + 'px' }
        }
    }

    function titleStyle() {
        if (stateTheme.theme.type === 'dark') {
            return { fontWeight: 'bold', fontSize: 20 + 'px', color: 'white' }
        } else {
            return { fontWeight: 'bold', fontSize: 20 + 'px', color: 'black' }
        }
    }

    function bubbleStyle() {
        if (stateTheme.theme.type === 'dark') {
            return { background: '#f9004d', border: 0 }
        } else {
            return { background: '#f9004d', border: 0 }
        }
    }

    function getRangeDate(startDate, finishDate) {
        let s = moment(startDate).format('MM/YYYY')
        let f = finishDate === "" ? moment(new Date()).format('MM/YYYY') : moment(finishDate).format('MM/YYYY')
        return s + ' - ' + f
    }

    return (
        <div className="single-tab-content">
            <Timeline>
                {experiences ?
                    experiences.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate)).map((el, index) => {
                        return (
                            <TimelineEvent
                                key={index}
                                createdAt={<p style={createdAt()}>{getRangeDate(el.startDate, el.finishDate)}</p>}
                                title={el.name + ' - ' + el.company}
                                contentStyle={contentStyle()}
                                titleStyle={titleStyle()}
                                bubbleStyle={bubbleStyle()}>
                                {el.description[lang]}

                                {<div className="mt-2 mb-2">
                                    <p>
                                        {TRANSLATE.ABOUT.EXPERIENCE.TECHNOLGIES_USED + ': '}
                                    </p>
                                </div>}

                                {el.technologiesUsed.map((t, i) => {
                                    return (
                                        <Badge key={i} pill variant="success" style={{ height: 25 + 'px', margin: 2 + 'px', padding: 6 + 'px' }}>
                                            {t}
                                        </Badge>
                                    )
                                })}
                            </TimelineEvent>
                        )
                    }) :
                    educations ? educations.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate)).map((el, index) => {
                        return (
                            <TimelineEvent
                                key={index}
                                createdAt={<p style={createdAt()}>{getRangeDate(el.startDate, el.finishDate)}</p>}
                                title={el.title + ' - ' + el.subtitle}
                                contentStyle={contentStyle()}
                                titleStyle={titleStyle()}
                                bubbleStyle={bubbleStyle()}>
                                {el.description[lang]}
                            </TimelineEvent>
                        )
                    }) : projects ? projects.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate).getTime()).map((el, index) => {
                        return (
                            <TimelineEvent
                                key={index}
                                createdAt={<p style={createdAt()}>{getRangeDate(el.startDate, el.finishDate)}</p>}
                                title={el.title}
                                contentStyle={contentStyle()}
                                titleStyle={titleStyle()}
                                bubbleStyle={bubbleStyle()}>
                                {el.description[lang]}

                                {<div className="mt-2 mb-2">
                                    <p>
                                        {TRANSLATE.ABOUT.EXPERIENCE.TECHNOLGIES_USED + ': '}
                                    </p>
                                </div>}

                                {el.technologiesUsed.map((t, i) => {
                                    return (
                                        <Badge key={i} pill variant="success" style={{ height: 25 + 'px', margin: 2 + 'px', padding: 6 + 'px' }}>
                                            {t}
                                        </Badge>
                                    )
                                })}
                            </TimelineEvent>
                        )
                    }) : ''
                }
            </Timeline>
        </div>
    )
}

export default Timelines