import React from "react"
import { Spinner } from 'react-bootstrap'

const SpinnerComponnet = () => {
    return (
        <div className="center">
            <Spinner animation="grow" className="spinner-style">
                <h1 style={{ color: 'white', fontSize: 100 + 'px' }} className="center">VS</h1>
            </Spinner>
        </div>
    )
}

export default SpinnerComponnet
