import React from 'react'
import {Document, Image, Page, StyleSheet, View,} from '@react-pdf/renderer'
import Header from '../../views/resume/Header'
import Education from '../../views/resume/Educations'
import Experience from '../../views/resume/Experiences'
import Projects from '../../views/resume/Projects'
import Skills from '../../views/resume/Skills'

const styles = StyleSheet.create({
    page: {
        padding: 30,
    },
    container: {
        flex: 1,
        flexDirection: 'row',
        '@media max-width: 400': {
            flexDirection: 'column',
        },
    },
    image: {
        marginBottom: 10,
        borderRadius: '100%',
    },
    leftColumn: {
        backgroundColor: '#ebedef',
        flexDirection: 'column',
        width: 170,
        padding: '30px 15px 15px 15px',
        '@media max-width: 400': {
            width: '100%',
            paddingRight: 0,
        },
        '@media orientation: landscape': {
            width: 200,
        },
    },
    rightColumn: {
        flexDirection: 'column',
        width: 400,
        paddingTop: 15,
        paddingRight: 15,
        '@media max-width: 400': {
            width: '100%',
            paddingRight: 0,
        },
        '@media orientation: landscape': {
            width: 200,
        },
    }
});

const Resume = (props) => {
    const {data, lang, translate} = props

    return (
        <Document>
            <Page {...props} style={styles.page}>
                <Header info={data.info}/>
                <View style={styles.container}>
                    <View style={styles.leftColumn}>
                        <Image src="/assets/images/profile.jpeg" style={styles.image}/>
                        <Education educations={data.educations} lang={lang} translate={translate}/>
                        <Skills skills={data.skills}/>
                    </View>
                    <View style={styles.rightColumn}>
                        <Experience experiences={data.experiences} lang={lang} translate={translate}/>
                        <Projects projects={data.projects} lang={lang} translate={translate}/>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

export default Resume
