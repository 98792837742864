import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/database'
import 'firebase/auth'
import 'firebase/storage'
import 'firebase/analytics'

let config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}

firebase.initializeApp(config)

const db = firebase.firestore()

export const getInfo = () => {
    return db.collection('info').get()
}

export const getEducations = () => {
    return db.collection('educations').get()
}

export const getExperiences = () => {
    return db.collection('experiences').get()
}

export const getSkills = () => {
    return db.collection('skills').get()
}

export const getProjects = () => {
    return db.collection('projects').get()
}

export const getParticles = () => {
    return db.collection('particles').get()
}

export default firebase
