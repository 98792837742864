import React, {useEffect, useState} from "react"
import {Container} from "react-bootstrap"
import {useAnalytics} from "../../utils/gaHook"
import {LanguageContext, languages} from '../../utils/context/LanguageContext'
import {ThemeContextProvider} from '../../utils/context/ThemeProvider'
import TheHeader from "./TheHeader"
import HeroSection from "../../views/home/HeroSection"
import AboutSection from "../../views/home/AboutSection"
import ProjectsSection from "../../views/home/ProjectsSection"
import ContactSection from "../../views/home/ContactSection"
import TheFooter from "./TheFooter"
//import Chat from '../../views/home/components/chat/Chat';
import SpinnerComponent from "../../components/shared/Spinner"
import * as FirestoreService from '../../firebase'

const Home = () => {

    const lang = window.location.pathname.includes("/it") ? "it" : "en"
    const [currentLang, setCurrentLang] = useState(languages[lang])

    const {init, trackPageViewed} = useAnalytics()
    const [info, setInfo] = useState({})
    const [educations, setEducations] = useState([])
    const [experiences, setExperiences] = useState([])
    const [skills, setSkills] = useState([])
    const [projects, setProjects] = useState([])

    useEffect(() => {
        init("UA-125815408-1")
        trackPageViewed()
        getInfo()
        getEducations()
        getExperiences()
        getSkills()
        getProjects()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getInfo = () => {
        FirestoreService.getInfo().then(res => {
            const items = res.docs.map(docSnapshot => docSnapshot.data())
            setInfo(items[0])
        })
    }

    const getEducations = () => {
        FirestoreService.getEducations().then(res => {
            const items = res.docs.map(docSnapshot => docSnapshot.data())
            setEducations(items)
        })
    }

    const getExperiences = () => {
        FirestoreService.getExperiences().then(res => {
            const items = res.docs.map(docSnapshot => docSnapshot.data())
            setExperiences(items)
        })
    }

    const getSkills = () => {
        FirestoreService.getSkills().then(res => {
            const items = res.docs.map(docSnapshot => docSnapshot.data())
            setSkills(items)
        })
    }

    const getProjects = () => {
        FirestoreService.getProjects().then(res => {
            const items = res.docs.map(docSnapshot => docSnapshot.data())
            setProjects(items)
        })
    }

    const data = {
        info: info,
        educations: educations,
        experiences: experiences,
        skills: skills,
        projects: projects
    }

    return (
        info && educations.length > 0 && experiences.length > 0 && skills.length > 0 && projects.length > 0 ?
            <div>
                <LanguageContext.Provider value={{currentLang, updateLang: lang => setCurrentLang(languages[lang])}}>
                    <ThemeContextProvider>
                        <TheHeader/>
                        <Container fluid style={{padding: 0}}>
                            <HeroSection data={data} lang={lang}/>
                            <AboutSection data={data} lang={lang}/>
                            <ProjectsSection data={data}/>
                            <ContactSection/>
                            <TheFooter data={data}/>
                        </Container>
                        {/* <Chat /> */}
                    </ThemeContextProvider>
                </LanguageContext.Provider>
            </div> :
            <div>
                <SpinnerComponent/>
            </div>
    )
}

export default Home
