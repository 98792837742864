import React from 'react'

import {Text, StyleSheet} from '@react-pdf/renderer'

const styles = StyleSheet.create({
    title: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 10,
        textTransform: 'uppercase',
        color: '#f8014d'
    }
})

const Title = ({children}) => <Text style={styles.title}>{children}</Text>

export default Title
