import React, { useState } from 'react'

export const ThemeContext = React.createContext({
    theme: {
        type: 'light',
        logo: 'logo-light.png',
        color: 'color-black'
    },
    setTheme: () => { }
})

export const ThemeContextProvider = props => {

    const theme = {
        light: {
            type: 'light',
            logo: 'logo-light.png',
            color: 'color-black'
        },
        dark: {
            type: 'dark',
            logo: 'logo-dark.png',
            color: 'color-white'
        },
    }

    const setTheme = t => {
        setState({ ...state, theme: t.type === 'dark' ? theme.light : theme.dark })
    }

    const initState = {
        theme: new Date().getHours() > 18 ? theme.dark : theme.light,
        setTheme: setTheme
    }

    const [state, setState] = useState(initState)

    return (
        <ThemeContext.Provider value={state}>
            <div className={`active-${state.theme.type}`}>
                {props.children}
            </div>
        </ThemeContext.Provider>
    )
}