import React from "react"
import { tranEn, tranIt } from "../lang/translate"

const languages = {
    it: {
        translate: tranIt
    },
    en: {
        translate: tranEn
    }
}

const LanguageContext = React.createContext(languages)

export { languages, LanguageContext }