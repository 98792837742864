import React, { useContext } from "react"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import { LanguageContext } from "../../../../utils/context/LanguageContext"
import Timeline from '../timeline/Timeline'
import Skills from '../skills/Skills'


const TabsList = (props) => {

    const { currentLang } = useContext(LanguageContext)
    const TRANSLATE = currentLang.translate

    const { tabStyle, educations, experiences, projects, skills } = props

    let tab1 = TRANSLATE.ABOUT.EXPERIENCE.TITLE
    let tab2 = TRANSLATE.ABOUT.EDUCATION_AND_CERTIFICATION
    let tab3 = TRANSLATE.ABOUT.PROJECTS.TITLE
    let tab4 = TRANSLATE.ABOUT.SKILLS

    return (
        <div>
            <div className="tabs-area">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <Tabs className="mt-2">
                                <TabList className={`${tabStyle}`}>
                                    <Tab>{tab1}</Tab>
                                    <Tab>{tab2}</Tab>
                                    <Tab>{tab3}</Tab>
                                    <Tab>{tab4}</Tab>
                                </TabList>
                                <TabPanel>
                                    <div className="single-tab-content">
                                        <Timeline experiences={experiences} lang={props.lang} />
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="single-tab-content">
                                        <Timeline educations={educations} lang={props.lang} />
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="single-tab-content">
                                        <Timeline projects={projects} lang={props.lang} />
                                    </div>
                                </TabPanel>
                                <TabPanel style={{ margin: 40 + 'px' }}>
                                    <div className="single-tab-content">
                                        <Skills skills={skills} />
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TabsList