import React from 'react'
import {View, StyleSheet} from '@react-pdf/renderer'

const styles = StyleSheet.create({
    line: {
        marginTop: '-5px',
        marginBottom: '15px',
        borderTop: '5px solid #f8014d'
    }
})

const Line = () => <View style={styles.line}/>

export default Line
