import React from 'react';
import ApplicationRoutes from './routes/ApplicationsRoutes';
import SpinnerComponnet from './components/shared/Spinner';
import './index.scss'

const loading = (
  <SpinnerComponnet></SpinnerComponnet>
)

function App() {
  return (
    <React.Suspense fallback={loading}>
      <ApplicationRoutes />
    </React.Suspense>
  );
}

export default App;

