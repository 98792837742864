import React from 'react'
import Title from './Title'
import Line from './Line'
import {StyleSheet, Text, View} from '@react-pdf/renderer'
import moment from 'moment'

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingTop: 15,
        paddingLeft: 15,
        '@media max-width: 400': {
            paddingTop: 10,
            paddingLeft: 0
        },
    },
    entryContainer: {
        marginBottom: 10
    },
    date: {
        fontSize: 9
    },
    description: {
        fontSize: 9,
        paddingLeft: 10
    },
    headerContainer: {
        flexDirection: 'row',
        marginBottom: 10
    },
    leftColumn: {
        flexDirection: 'column',
        flexGrow: 9
    },
    rightColumn: {
        flexDirection: 'column',
        flexGrow: 1,
        alignItems: 'flex-end',
        justifySelf: 'flex-end'
    },
    title: {
        fontSize: 10,
        textTransform: 'uppercase',
        textDecoration: 'underline',
        color: '#000'
    }
})

const ExperienceEntry = ({company, position, desc, technologiesUsed, startDate, finishDate}) => {
    const title = `${company} | ${position}`

    function getRangeDate(startDate, finishDate) {
        let s = moment(startDate).format('MM/YYYY')
        let f = finishDate === "" ? moment(new Date()).format('MM/YYYY') : moment(finishDate).format('MM/YYYY')
        return s + ' - ' + f
    }

    return (
        <View style={styles.entryContainer}>
            <View style={styles.headerContainer}>
                <View style={styles.leftColumn}>
                    <Text style={styles.title}>{title}</Text>
                </View>
                <View style={styles.rightColumn}>
                    <Text style={styles.date}>{getRangeDate(startDate, finishDate)}</Text>
                </View>
            </View>
            <Text style={styles.description}>
                {desc}
            </Text>
            <Text style={{fontSize: 10, paddingLeft: 10, paddingTop: 5}}>
                <Text style={{fontWeight: 'bold'}}>Tecnologie
                    utilizzate:</Text> {technologiesUsed.map(t => t + ' ')}
            </Text>
        </View>
    )
}

const Experience = (props) => {

    const {experiences, lang, translate} = props

    return (
        <View style={styles.container}>
            <Title>
                {translate.translate.ABOUT.EXPERIENCE.TITLE}
            </Title>
            <Line/>
            {experiences.sort((a, b) => new Date(b.startDate).getTime() - new Date(a.startDate)).map((el, i) => (
                <ExperienceEntry
                    key={i}
                    company={el.company}
                    position={el.name}
                    startDate={el.startDate}
                    finishDate={el.finishDate}
                    desc={el.description[lang]}
                    technologiesUsed={el.technologiesUsed}
                />
            ))}
        </View>
    )
}

export default Experience
