import React from 'react'
import Group from './SkillItemGroup'

const Skills = (props) => {

    const { skills } = props

    return (
        <section className="has-text-centered">
            <div className="container">
                {
                    skills.map((item, key) => (
                        <Group key={key} name={item.name} items={item.list} />
                    ))
                }
            </div>
        </section>
    )
}

export default Skills