import React, { useContext, useEffect, useState } from "react"
import TextLoop from "react-text-loop"
import { LanguageContext } from '../../utils/context/LanguageContext'
import { Button } from "react-bootstrap"
import Resume from "../../containers/resume/Resume"
import { PDFDownloadLink } from '@react-pdf/renderer'
import ParticlesComponent from "../../components/shared/Particles"
import useSound from 'use-sound'
import soundDownload from '../../sounds/rising-pops.mp3'

const HeroSectionElement = (props) => {

    const { currentLang } = useContext(LanguageContext)
    const data = props.data
    const TRANSLATE = currentLang.translate
    const [visibile, setVisibile] = useState(false)
    const [playOn] = useSound(soundDownload)

    useEffect(() => {
        setInterval(() => {
            setVisibile(true)
        }, 2000)
    }, [visibile])

    return (
        <div id="home" className="fix">
            <div className="hero-section-wrapper">
                <div className="hero-section personal-portfolio-hero-section hero-section-paralax hero-section-style-3 d-flex align-items-center justify-content-center bg_color--5">
                    <ParticlesComponent />
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="inner text-left">
                                    <span>{TRANSLATE.SLIDER.WELCOME_TO_MY_WORLD}</span>
                                    <h1 className="title">{TRANSLATE.SLIDER.HI} {data.info.firstName} {data.info.lastName}<br />
                                        <TextLoop>
                                            <span>{data.info.job}</span>
                                        </TextLoop>{" "}
                                    </h1>
                                    <div className="hero-section-btn">
                                        {visibile ? (
                                            <PDFDownloadLink document={<Resume data={data} lang={props.lang} translate={currentLang} />} fileName="cv-vincenzo-spinelli.pdf">
                                                {({ loading }) => (loading ? <p>Loading...</p> : <Button className="rn-button-style--2 btn-solid" type="submit" onClick={playOn}>Download CV!</Button>)}
                                            </PDFDownloadLink>
                                        ) : (<p>Loading...</p>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeroSectionElement
