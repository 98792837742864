import React, { useContext } from 'react'
import { ThemeContext } from '../../utils/context/ThemeProvider'
import { DarkModeSwitch } from 'react-toggle-dark-mode'
import useSound from 'use-sound'
import switchOn from '../../sounds/switch-on.mp3'
import switchOf from '../../sounds/switch-off.mp3'

const ThemeToggle = () => {

    const state = useContext(ThemeContext)
    const isDarkMode = state.theme.type === 'dark' ? true : false
    const [playOn] = useSound(switchOn)
    const [playOff] = useSound(switchOf)

    function onClick() {
        state.theme.type === 'dark' ? playOff() : playOn()
        state.setTheme(state.theme)
    }

    return (
        <div style={{ width: 48 + 'px', height: 48 + 'px', borderRadius: 100 + '%', marginTop: 0 + 'px' }} onClick={onClick}>
            <DarkModeSwitch
                checked={!isDarkMode}
                onChange={onClick}
                size={22}
                moonColor="black"
                sunColor="white"
            />
        </div>
    )
}

export default ThemeToggle