import React from 'react'
import Title from './Title'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import Line from "./Line";

const styles = StyleSheet.create({
    title: {
        fontSize: 11,
        marginBottom: 10,
        textDecoration: 'underline'
    },
    skills: {
        fontSize: 10,
        marginBottom: 10,
    },
})

const SkillEntry = ({ skills }) => {
    return (
        <View>
            <Text style={styles.title}>{skills.name}</Text>
            <Text style={styles.skills}> {skills.list.map((el, i) => el.name + '  ')}</Text>
        </View>
    )
}

const Skills = (props) => {

    const { skills } = props

    let fe = skills.find(el => el.name === 'Front-ends')
    let be = skills.find(el => el.name === 'Back-ends')
    let db = skills.find(el => el.name === 'Databases')
    let tools = skills.find(el => el.name === 'Tools')

    return (
        <View>
            <Title>Skills</Title>
            <Line/>
            <SkillEntry
                skills={fe}
            />
            <SkillEntry
                skills={be}
            />
            <SkillEntry
                skills={db}
            />
            <SkillEntry
                skills={tools}
            />
        </View>
    )
}

export default Skills
